import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
import ResetPassword from "../components/resetpassword"

const LoginPage = (props) => {

	//TODO:: Redirect user to myaccount page if they are already logged in
	return (
		<main>
			<Header/>
				<div className="container pt-60 mb-30">
					<div className="row justify-content-center">
						<div className="col-xs-12 col-md-4 text-center">
 							<ResetPassword type={props.pageContext.params}/>
						</div>
					</div>
				</div>
			<Footer/>
		</main>
	)
}

export default LoginPage