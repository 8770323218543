import React from "react"
import {Link} from "gatsby"
import {resetPassBrand, resetPass} from "../services/auth"
import {navigate} from "gatsby";

class resetpassword extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        code: ``, password: ``, errorspinner: ``, passwordConfirmation: ``, success: ``, error: ``
    }

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState((state, props) => {
            return {
                errorspinner: true,
            };
        });

        if (this.state.passwordConfirmation === this.state.password) {
            if (this.props.type === 'user') {
                var lgres = await resetPass(this.state)
            }
            if (this.props.type === 'brand') {
                var lgres = await resetPassBrand(this.state)
            }
            if (lgres === false) {
                this.setState((state, props) => {
                    return {
                        password: '', passwordConfirmation: '', error: "Invalid code try again please"
                    };
                });
                setTimeout(() => {
                    this.setState({
                        error: '',
                    });
                }, 1500);
                this.setState((state, props) => {
                    return {
                        errorspinner: '',
                    };
                });
            } else {
                this.setState({
                    success: 'Password updated successfully',
                });
                setTimeout(() => {
                    if (this.props.type === 'brand') {
                        navigate('/brand-login')
                    } else {
                        navigate('/login')
                    }
                }, 1500);

            }
        } else {
            this.setState((state, props) => {
                return {error: "Password does not match"};
            });
            setTimeout(() => {
                this.setState({error: '',});
            }, 1500);
        }
    }

    componentDidMount() {
        if (typeof window !== `undefined`) {
            const queryString = window.location.search;
            let code = queryString.replace('?code=', '');
            this.setState((state, props) => {
                return {code: code};
            })
        }
    }

    render() {
        return (<>
            <h1>Reset Password</h1>
            {this.state.error !== "" &&
                <div className="err alert alert-danger">{this.state.error}</div>}
            {this.state.success !== "" &&
                <div className="err alert alert-success">{this.state.success}</div>}
            <form method="post" onSubmit={this.handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label text-left">New Password</label>
                    <input type="password" name="password" className="form-control" onChange={this.handleUpdate}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Confirm Password</label>
                    <input type="password" name="passwordConfirmation" className="form-control" onChange={this.handleUpdate}/>
                </div>
                <div className="mb-3">
                    <button type="submit" className="btn btn-primary">
                        Update Password
                        {this.state.errorspinner !== "" &&
                            <div className="spinner-border spinner-bordersignup" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>}
                    </button>
                </div>
            </form>
            <p className="text-center">Don't have account? <Link to="/login">Login Here</Link></p>
        </>)
    }
}

export default resetpassword